<template>
  <div>
    <div class="title">合同模板</div>
    <div class="form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="180px"
        style="margin: 0 auto;"
        size="small"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="品名：" prop="name">
              <el-input
                v-model.trim="form.commodityName"
                style="width: 260px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否默认：" prop="name">
              <el-select
                v-model.trim="form.defaultOrNot"
                placeholder="请选择"
                style="width: 260px;"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- <el-form-item label="收藏日期：" prop="name">
              <el-date-picker v-model.trim="form.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item> -->
            <el-form-item prop="name">
              <div style="display:flex;margin-top:3px;">
                <div class="search-btn" @click="search">搜索</div>
                <div class="reset-btn" @click="reset">重置</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="table">
      <el-table
        :data="tableData"
        :header-cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }"
        :cell-style="{
          'text-align': 'center',
          'font-weight': 'normal',
          'font-size': '12px',
          color: '#333'
        }"
        style="width: 100%"
        size="mini"
      >
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop !== 'operate' && item.prop !== 'defaultOrNot'"
              >{{ scope.row[item.prop] }}</span
            >
            <span v-if="item.prop == 'defaultOrNot'">{{
              scope.row[item.prop] == 1 ? "默认" : "不默认"
            }}</span>
            <div v-if="item.prop == 'operate'">
              <el-button
                v-for="(item, index) in scope.row.operate"
                :key="index"
                type="text"
                size="mini"
                @click="edit(scope.row)"
                >{{ item.name }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <fieldset style="border:1px solid #e5e5e5;">
        <legend>合同模板说明</legend>
        <ul style="margin-left:324px;">
          <li class="list">1.该模板的条款将被用作用户合同的条款</li>
          <li class="list">2.条款禁止涉及不良信息</li>
        </ul>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        commodityName: "",
        defaultOrNot: "",
        date: []
      },
      rules: {},
      tableData: [],
      tableHeader: [],
      options: [
        {
          value: "1",
          label: "全部"
        },
        {
          value: "2",
          label: "否"
        },
        {
          value: "3",
          label: "是"
        }
      ],
      value: ""
    };
  },
  created() {
    this.getContractList();
  },
  methods: {
    search() {
      this.getContractList();
    },
    getContractList() {
      let params = {
        process_id: "00000205", //流程ID
        //传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: -1 /*分页页容量（从0开始，-1代表查全部）*/,
          page: 0 /*分页页码（从0开始）*/,
          commodityName: [this.form.commodityName] /*品名*/,
          collectionBeginTime:
            this.form.date.length == 0
              ? ""
              : this.form.date[0] /*收藏开始时间？？？*/,
          collectionEndTime:
            this.form.date.length == 0
              ? ""
              : this.form.date[1] /*收藏截止时间？？？*/,
          defaultOrNot: this.form.defaultOrNot /*是否需默认（0-否/1-是）*/
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(result => {
          const { data } = result;
          this.options = data.downlist;
          this.tableHeader = data.title;
          this.tableData = data.content;
        })
        .catch(err => {});
    },
    reset() {
      this.form = {
        commodityName: "",
        defaultOrNot: "1",
        date: []
      };
      this.getContractList();
    },
    edit(data) {
      this.$router.push({
        path: "/contractTempDetail",
        query: { id: data.templateId, name: data.templateName, 'process_id': '00000205' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.form {
  border: #e5e5e5 1px solid;
  line-height: 40px;
  margin: 4px auto;
  padding: 5px;
  .search-btn,
  .reset-btn {
    background: #c3214a;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    width: 66px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin-right: 15px;
  }
  .reset-btn {
    background: #2d5171;
  }
}
.table {
  margin-top: 20px;
}
.footer {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  ul {
    list-style-type: none;
  }
  .list {
    line-height: 23px;
    &::before {
      content: "";
    }
  }
}
</style>
